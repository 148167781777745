import { format, set } from 'date-fns'

export const dateFormat = "yyyy-MM-dd'T'HH:mm"
const now = new Date()
export const DEFAULT_SELECTED_FILTERS = [
  'critical_departure_start_time',
  'critical_departure_end_time',
]
export const defaultLoadSearchFilters = (defaultFilters = true) => {
  return {
    load_ids: [],
    pro_numbers: [],
    scacs: [],
    trailer_numbers: [],
    origin_ids: [],
    destination_ids: [],
    departure_log_statuses: [],
    alert_statuses: [],
    category_list: [],
    critical_departure_start_time: defaultFilters
      ? format(
          set(now, {
            hours: 0,
            minutes: 0,
          }),
          dateFormat,
        )
      : '',
    critical_departure_end_time: defaultFilters
      ? format(
          set(now, {
            date: now.getDate() + 1,
            hours: 11,
            minutes: 59,
          }),
          dateFormat,
        )
      : '',
    arrival_start_time: '',
    arrival_end_time: '',
    cut_time_start_time: '',
    cut_time_end_time: '',
  }
}

export const FILTER_LABELS = {
  load_ids: 'Load IDs',
  pro_numbers: 'Pro Numbers',
  scacs: 'SCACs',
  trailer_numbers: 'Trailer #',
  origin_ids: 'Origin',
  destination_ids: 'Destination',
  departure_log_statuses: 'Departure Status',
  alert_statuses: 'Alert Status',
  category_list: 'Load Category',
  critical_departure_start_time: 'Critical Departure Start Time',
  critical_departure_end_time: 'Critical Departure End Time',
  arrival_start_time: 'Arrival Start Time',
  arrival_end_time: 'Arrival End Time',
  cut_time_start_time: 'Cut Time From Date Time',
  cut_time_end_time: 'Cut Time To Date Time',
}
