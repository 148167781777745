import axios from 'axios'

import apiConfig from '../../../config/apiConfig'
import { get } from 'lodash'
import { format } from 'date-fns'

async function getSavedSearches() {
  try {
    const { data } = await axios.get(
      `${apiConfig.api.edge}/saved_search/DEPARTURE_LOG_SAVED_SEARCH`,
      {},
    )
    return mapResponseToSavedSearchData(data)
  } catch (e) {
    return get(e, 'response.status') === 404
      ? 'No saved searches'
      : 'Error getting saved searches'
  }
}

function formatDateTimeToFilterValues(dateTime, desiredFormat) {
  return dateTime ? format(new Date(dateTime), desiredFormat) : ''
}

function mapCriteriaToSearchFilters(criteria) {
  const minuteFormat = "yyyy-MM-dd'T'HH:mm"
  const loadPredicate = criteria.predicate_json.load_predicate
  return {
    load_ids: loadPredicate.load_ids,
    scacs: loadPredicate.scacs,
    trailer_numbers: loadPredicate.trailer_numbers,
    origin_ids: loadPredicate.origin_ids?.map((id) => {
      return { label: id, value: id, id: id }
    }),
    destination_ids: loadPredicate.destination_ids?.map((id) => {
      return { label: id, value: id, id: id }
    }),
    departure_log_statuses: loadPredicate.status_list,
    alert_statuses: loadPredicate.alert_status_list,
    category_list: loadPredicate.category_list,
    subcategories: [],
    arrival_start_time: formatDateTimeToFilterValues(
      loadPredicate.arrival_time?.start,
      minuteFormat,
    ),
    arrival_end_time: formatDateTimeToFilterValues(
      loadPredicate.arrival_time?.end,
      minuteFormat,
    ),
    cut_time_start_time: formatDateTimeToFilterValues(
      loadPredicate.cut_time?.start,
      minuteFormat,
    ),
    cut_time_end_time: formatDateTimeToFilterValues(
      loadPredicate.cut_time?.end,
      minuteFormat,
    ),
  }
}

function mapResponseToSavedSearchData(response) {
  if (!response) return []
  return response.map((searchCriteria) => {
    let loadPredicate = searchCriteria.predicate_json.load_predicate
    return {
      search_name: loadPredicate.search_name,
      criteria_id: searchCriteria.criteria_id,
      default_search: loadPredicate.default_search,
      filters: mapCriteriaToSearchFilters(searchCriteria),
    }
  })
}

async function deleteSavedSearch(id) {
  const { data } = await axios.delete(
    `${apiConfig.api.edge}/saved_search/${id}`,
    {},
  )
  return data
}

async function setDefaultSavedSearch(id) {
  const { data } = await axios.patch(
    `${apiConfig.api.edge}/saved_search/${id}`,
    {},
  )
  return data
}

function mapFilterValuesToCriteria(filters) {
  return {
    predicate_json: {
      load_predicate: {
        search_name: filters.search_name,
        load_ids: filters.load_ids,
        origin_ids: filters.origin_ids.map((node) => node.value),
        destination_ids: filters.destination_ids.map((node) => node.value),
        trailer_numbers: filters.trailer_numbers,
        status_list: filters.departure_log_statuses,
        scacs: filters.scacs,
        alert_status_list: filters.alert_statuses,
        arrival_time: {
          start: formatDateTimeToCriteriaValues(filters.arrival_start_time),
          end: formatDateTimeToCriteriaValues(filters.arrival_end_time),
        },
        category_list: filters.category_list,
        cut_time: {
          start: formatDateTimeToCriteriaValues(filters.cut_time_start_time),
          end: formatDateTimeToCriteriaValues(filters.cut_time_end_time),
        },
      },
    },
    criteria_type: 'DEPARTURE_LOG_SAVED_SEARCH',
  }
}

function formatDateTimeToCriteriaValues(dateTime) {
  return dateTime ? new Date(dateTime).toISOString() : null
}

async function saveSearch(loadSearchFilters) {
  const saveSearchCriteria = mapFilterValuesToCriteria(loadSearchFilters)
  const { data } = await axios.post(
    `${apiConfig.api.edge}/saved_search`,
    saveSearchCriteria,
    {},
  )
  return data
}

export default {
  getSavedSearches,
  saveSearch,
  deleteSavedSearch,
  setDefaultSavedSearch,
}
