import { isArray, upperCase } from 'lodash'

import { NovaFilterDrawer } from '@dlm/common'
import { defaultLoadSearchFilters } from '../constants/searchFilterConstants'
import { originDestinationFilterValidationRule } from '../../../util/validationUtil'

const mapLoadCategoryOptions = (categories) => {
  const mappedCategories = []
  categories.forEach((category) => {
    const mappedCategory = {
      label: upperCase(category.type),
      values: [],
    }

    category.sub_types.forEach((subCategory) => {
      mappedCategory.values.push({
        value: subCategory,
        label: upperCase(subCategory),
      })
    })

    mappedCategories.push(mappedCategory)
  })

  return mappedCategories
}

const SearchFilterDrawer = ({
  onChange,
  isVisible,
  onRequestClose,
  loadStatusOptions,
  alertStatusOptions,
  loadCategories,
  nodeInfo,
  givenValues,
  onSaveSearch,
  savedSearches,
}) => {
  const categoryOptions = mapLoadCategoryOptions(loadCategories)

  const handleChange = (values) => {
    // Needed to strip out object metadata added by hook form for tests to pass
    onChange(Object.assign({}, values))
    onRequestClose()
  }

  function handleSaveSearch(values) {
    onSaveSearch(values)
  }

  const filterMeta = {
    1: [
      {
        type: 'datetime-local',
        name: 'critical_departure_start_time',
        label: 'Critical Departure Start Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'critical_departure_end_time',
        label: 'Critical Departure End Time',
        xs: 6,
      },
    ],
    2: [
      {
        type: 'datetime-local',
        name: 'arrival_start_time',
        label: 'Arrival Start Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'arrival_end_time',
        label: 'Arrival End Time',
        xs: 6,
      },
    ],
    3: [
      {
        type: 'datetime-local',
        name: 'cut_time_start_time',
        label: 'Cut Time From Date Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'cut_time_end_time',
        label: 'Cut Time To Date Time',
        xs: 6,
      },
    ],
    4: [
      {
        type: 'autocomplete',
        name: 'origin_ids',
        label: 'Origin',
        multiselect: true,
        ariaLabel: 'Origin Location ID List',
        allowCustomInputValue: true,
        rules: originDestinationFilterValidationRule(),
        placeholder: 'Comma separated list of Origin IDs',
        options: nodeInfo,
        xs: 6,
      },
      {
        type: 'autocomplete',
        name: 'destination_ids',
        multiselect: true,
        label: 'Destination',
        ariaLabel: 'Destination Location ID List',
        allowCustomInputValue: true,
        rules: originDestinationFilterValidationRule(),
        placeholder: 'Comma separated list of Destination IDs',
        options: nodeInfo,
        xs: 6,
      },
    ],
    5: [
      {
        type: 'multi-text',
        name: 'scacs',
        label: 'SCACs',
        ariaLabel: 'SCAC List',
        placeholder: 'Comma separated list of SCACs',
        xs: 6,
      },
      {
        type: 'multi-text',
        name: 'trailer_numbers',
        label: 'Trailer Numbers',
        ariaLabel: 'Trailer Number List',
        placeholder: 'Comma separated list of Trailer Numbers',
        xs: 6,
      },
    ],
    6: [
      {
        type: 'multi-text',
        name: 'load_ids',
        label: 'Load IDs',
        ariaLabel: 'Load ID List',
        placeholder: 'Comma separated list of Load IDs',
        xs: 12,
      },
    ],
    7: [
      {
        type: 'multi-text',
        name: 'pro_numbers',
        label: 'Pro Numbers',
        ariaLabel: 'Pro Number List',
        placeholder: 'Comma separated list of Pro Numbers',
        xs: 12,
      },
    ],
    8: [
      {
        type: 'checkboxes',
        name: 'departure_log_statuses',
        label: 'Departure Status',
        options: loadStatusOptions,
        xs: 6,
      },
      {
        type: 'checkboxes',
        name: 'alert_statuses',
        label: 'Alert Status',
        options: alertStatusOptions,
        xs: 6,
      },
    ],
    9: [
      {
        type: 'checkbox-groups',
        name: 'category_list',
        label: 'Load Category',
        options: categoryOptions,
        xs: 12,
        direction: 'row',
      },
    ],
  }
  const existingSearches = isArray(savedSearches) ? savedSearches : []

  const filterActionsMeta = {
    1: [
      {
        type: 'reset',
        xs: 6,
      },
      {
        type: 'save-search',
        xs: 6,
        action: handleSaveSearch,
        currentSavedSearches: existingSearches,
        noteToUser:
          'Critical Departure Time is neither stored nor overridden by a saved search. It must be default or set manually.',
      },
    ],
    2: [
      {
        type: 'submit',
        xs: 12,
      },
    ],
  }
  return (
    <NovaFilterDrawer
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      headingText="Search Filters"
      location="right"
      filterMeta={filterMeta}
      filterActionsMeta={filterActionsMeta}
      values={givenValues}
      defaultValues={defaultLoadSearchFilters(true)}
      onSubmit={handleChange}
    />
  )
}

export default SearchFilterDrawer
